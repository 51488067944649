<template>
  <div class="block_layout">
    <SecondSectionTab
      :parentColumnName="parentColumnName"
      :columnId="columnId"
    ></SecondSectionTab>
    <div class="container">
      <div class="content">
        <template v-if="this.newsList.length > 0">
          <div class="internal" v-for="(item, index) in newsList" :key="index">
            <head>
              <div class="head-left" v-if="childColumnList.length">
                {{ item.title }}
              </div>
              <div
                class="head-right"
                v-if="item.list.length > 0"
                @click="handleMore(item.level, index)"
              >
                <h2>更多>></h2>
              </div>
              <div class="head-right" v-else></div>
            </head>
            <div v-if="item.list.length > 0">
              <div
                class="title"
                @click="handleDetail(item.list[0], index)"
                v-if="item.list.length"
              >
                要闻聚焦 | {{ item.list[0].title }}
              </div>
              <ul>
                <li
                  v-for="(detailItem, i) in item.list"
                  :key="detailItem.id"
                  v-show="item.isTop != 0 && i != 0"
                  @click="handleDetail(detailItem, index)"
                >
                  <div class="liContent">
                    {{ item.length == 0 ? "" : detailItem.title }}
                  </div>
                  <span class="date">{{
                    detailItem.releaseTime | escapeHTML
                  }}</span>
                </li>
              </ul>
            </div>
            <div class="no_data" v-else>暂无数据</div>
          </div>
        </template>
        <template v-else>
          <div
            class="internal"
            v-for="(tempItem, tempIndex) in 3"
            :key="tempIndex"
          >
            <head>
              <div class="head-left">
                暂无子栏目
              </div>
              <div class="head-right">
                <!-- <h2>更多>></h2> -->
              </div>
            </head>
            <!-- <div class="temp_content"></div> -->
            <div class="no_data">暂无数据</div>
          </div>
        </template>
      </div>
      <div class="notice">
        <!-- <Agrright></Agrright> -->
        <CommonNotice
          :parentColumnName="parentColumnName"
          :columnId="columnId"
        ></CommonNotice>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { getInforId, getContentList, getWebTitle } from "@/api/home1";
import SecondSectionTab from "@/components/secondSectionTab";
// import Agrright from "@/views/agricuinfor/components/agrright";
import CommonNotice from "@/components/commonNotice";
import Footer from "@/views/components/footer.vue";
export default {
  components: {
    SecondSectionTab,
    // Agrright,
    CommonNotice,
    Footer
  },
  props: {
    columnId: {
      type: String,
      default: ""
    },
    parentColumnName: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      childColumnList: [],
      internalList: [],
      externalList: [],
      newList: [],
      aboutname: "",
      columid: "",
      paths: "",
      //   detailList: [],
      newsList: []
    };
  },
  mounted() {
    this.fetchChildColumn();
    this.fetchWebTitle();
  },
  methods: {
    //获取网站名称
    fetchWebTitle() {
      getWebTitle().then(res => {
        if (!res.data.records[0].status) {
          document.title = res.data.records[0].websiteName;
        } else {
          document.title = "";
        }
      });
    },
    handleMore(level, i) {
      this.$router.push({
        path: "/childlayout",
        query: {
          columnNameId: this.childColumnList[i].id,
          level: level
        }
      });
    },
    //获取二级栏目列表
    fetchChildColumn() {
      //   getInforId({ parentId: this.columnData.information.id }).then(result => {
      getInforId({ parentId: this.columnId }).then(result => {
        this.childColumnList = result.data;
        this.fetchDetailList();
      });
    },
    //获取栏目详情
    fetchDetailList() {
      for (let index = 0; index < this.childColumnList.length; index++) {
        let data = {};
        data.list = [];
        data.title = this.childColumnList[index].columnName;
        this.newsList.push(data);
        getContentList({
          columnId: this.childColumnList[index].id,
          size: 7
        }).then(res => {
          this.newsList[index].list = res.data.records;
        });
      }
    },
    //正文
    handleDetail(data) {
      let routerJump = this.$router.resolve({
        path: "/domesticDetails",
        query: {
          titleId: data.id
        }
      });
      window.open(routerJump.href, "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.content {
  //   max-width: 770px;
  width: 58%;
  padding-left: 10%;
  head {
    display: flex;
    justify-content: flex-start;
    padding-right: 10px;
    .head-left {
      color: #fff;
      font-size: 18px;
      text-align: center;
      width: 100px;
      background: #51d5c3;
      line-height: 30px;
      text-align: center;
      position: relative;
      border-bottom: 2px solid #08a085;
    }
    .head-left::after {
      content: "";
      width: 1px;
      height: 5px;
      display: inline-block;
      border: 5px solid transparent;
      border-top: 5px solid #08a085;
      font-size: 100% 100%;
      position: absolute;
      left: 40px;
      bottom: -15px;
    }
    .head-right {
      width: 700px;
      color: #7e7e7e;
      border-bottom: 2px solid #c2c2c2;
      padding-top: 4px;
      margin-right: 10px;
      cursor: pointer;
      h2 {
        float: right;
        margin-right: 20px;
      }
      .more {
        color: #646464;
        font-size: 13px;
        float: right;
        padding-right: 14px;
        background-image: url("../../assets/img/icon_more.png");
        background-size: auto 10px;
        background-repeat: no-repeat;
        background-position: center right;
        display: inline-block;
        cursor: pointer;
        margin-top: 5px;
      }
    }
  }
  .temp_content {
    height: 180px;
  }
  .title {
    font-size: 16px;
    font-weight: bolder;
    margin: 16px 0;
    cursor: pointer !important;
  }
  ul {
    height: 210px;
    padding-left: 20px;
    overflow: auto;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 10px;
    li {
      font-size: 14px;
      list-style-type: disc;
      line-height: 30px;
      // display: flex;
      // justify-content: space-between;
      .liContent {
        // display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 430px;
      }
      .date {
        float: right;
        margin-right: 50px;
        position: relative;
        top: -30px;
      }
      p {
        width: 430px;
      }
    }
    li:hover {
      color: #51d5c3;
    }
  }
}
.notice {
  width: 30%;
  padding-right: 4%;
}
.head-right h2:hover,
.title:hover {
  color: #51d5c3;
}
.internal-img {
  width: 100%;
  margin-bottom: 10px;
}
.no_data {
  height: 180px;
  line-height: 180px;
  color: #999;
  text-align: center;
}
</style>
