<template>
  <div class="common_container">
    <BlockLayout
      v-if="this.level === '1'"
      :columnId="columnNameId"
      :parentColumnName="columnName"
    ></BlockLayout>
    <TextLayout
      v-else-if="this.level === '2'"
      :columnNameId="columnNameId"
    ></TextLayout>
    <ThumbnailLayout
      v-else-if="this.level === '3'"
      :columnNameId="columnNameId"
    ></ThumbnailLayout>
  </div>
</template>

<script>
import BlockLayout from "@/components/commonLayout/blockLayout";
import TextLayout from "@/components/commonLayout/textLayout";
import ThumbnailLayout from "@/components/commonLayout/thumbnailLayout";
export default {
  name: "commonLayout",
  components: {
    BlockLayout,
    TextLayout,
    ThumbnailLayout
  },
  data() {
    return {
      level: "",
      columnNameId: "",
      columnName: "",
      parentId: ""
    };
  },
  mounted() {
    this.level = this.$route.query.level;
    this.columnNameId = this.$route.query.columnNameId;
    this.columnName = this.$route.query.columnName;
    this.parentId = this.$route.query.parentId;
  }
};
</script>
