<!--
 * @Author: madsion
 * @Date: 2021-10-21 16:41:14
 * @LastEditors: madsion
 * @LastEditTime: 2021-10-28 10:02:41
 * @Description: 
-->
<template>
  <div class="second_tab">
    <div class="tab">
      <div class="parent_tab">
        <div>{{ parentColumnName }}</div>
      </div>
      <div class="child_tab">
        <ul>
          <template v-for="(item, idx) in childColumnList">
            <li
              :key="item.id"
              :class="indexs === idx ? 'active' : ''"
              @click="handleChildColumn(item, idx)"
              v-if="item.isHome === 0"
            >
              {{ item.columnName }}
            </li>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { getQuotes } from "@/api/agricuinfor";
export default {
  name: "secondSectionTab",
  props: {
    columnId: {
      type: String,
      default: ""
    },
    parentColumnName: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      childColumnList: [],
      indexs: 0
    };
  },
  mounted() {
    this.fetchSecondSection();
  },
  methods: {
    fetchSecondSection() {
      getQuotes({ parentId: this.columnId }).then(res => {
        this.childColumnList = res.data;
      });
    },
    handleChildColumn(data, idx) {
      this.indexs = idx;
      this.$router.push({
        path: "/childlayout",
        // path: "/commonLayout",
        query: {
          columid: this.columid,
          columnNameId: data.id,
          level: data.level,
          columnName: data.columnName
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.tab {
  //   width: 1300px;
  width: 95%;
  height: 50px;
  display: flex;
  //   margin-bottom: 30px;
  //   margin: 0 30px 30px 30px;
  margin: 0 auto 30px auto;
  //   padding-top: 10px;
  //   padding-bottom: 4px;
  padding: 10px 10px 4px 10px;
  border-top: 5px solid #51d5c3;
  border-bottom: 2px solid #51d5c3;
  .parent_tab {
    width: 22%;
    margin-left: 8%;
    div {
      line-height: 50px;
      font-weight: bolder;
      font-size: 16px;
    }
  }
  .tableft {
    width: 242px;
    margin: 5px 0 0 10px;
    font-weight: bolder;
    font-size: 16px;
  }
  .ulBox {
    margin: 0 auto;
  }
  ul {
    display: flex;
    justify-content: center;
    li {
      font-size: 18px;
      line-height: 50px;
      margin-right: 70px;
      font-weight: bolder;
      cursor: pointer;
    }
    .active {
      border-bottom: 4px solid #51d5c3;
    }
  }
}
</style>
