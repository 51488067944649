<template>
  <div class="container">
    <div class="banner" v-if="this.Img.length > 0">
      <div class="item" @mouseover="mouseOver" @mouseleave="mouseLeave">
        <img :src="Img[currentIndex]" @click="handleBanner(currentIndex)" />
      </div>
      <div class="banner_detail" v-if="this.Img.length">
        <div class="detail_title">{{ label[currentIndex] | ellipsis }}</div>
        <ul class="detail_list">
          <li
            class="detail_item"
            v-for="(item, index) in Img"
            :key="index"
            @click="gotoPage(index)"
            :class="{ current: currentIndex === index }"
          ></li>
        </ul>
      </div>
    </div>
    <div class="news">
      <h1 class="news_header">今日热闻<span class="hot_font">HOT</span></h1>
      <ul>
        <li
          class="news_item"
          v-for="(item, idx) in relnew"
          :key="idx"
          @click="handleNewsDetail(item)"
        >
          <span>{{ idx + 1 }}. </span>{{ item.title }}
        </li>
      </ul>
    </div>
    <div class="special_subject" v-if="this.zti">
      <div class="special_header">
        <h1 class="title">
          {{ zti.columnName }}
        </h1>
        <p class="more" v-if="relhot.length > 0" @click="handleMore()">
          更多>>
        </p>
      </div>
      <div class="special_list">
        <ul>
          <li
            class="special_list_item"
            v-for="(item, idx) in relhot.slice(0, 4)"
            :key="idx"
            @click="openNewWindow(item)"
          >
            <img :src="item.icon" alt="" />
            <div class="position">
              <h3>{{ item.title }}</h3>
              <p>{{ item.releaseTime.slice(0, 16) }}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="special_subject" v-else>
      <div class="special_header">
        <h1 class="title">
          暂无子栏目
        </h1>
        <!-- <p class="more" @click="handleMore()">更多>></p> -->
      </div>
      <div class="special_list">
        <ul>
          <li
            class="special_list_item"
            v-for="(item, idx) in relhot.slice(0, 4)"
            :key="idx"
            @click="openNewWindow(item)"
          >
            <img :src="item.icon" alt="" />
            <div class="position">
              <h3>{{ item.title }}</h3>
              <p>{{ item.releaseTime.slice(0, 16) }}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getNews,
  getQuotes,
  getQuotesList,
  getdetail
} from "@/api/agricuinfor";
export default {
  props: {
    columnId: {
      type: String,
      default: ""
    },
    parentColumnName: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      relnew: "",
      relhot: [],
      Img: [],
      label: [],
      currentIndex: 0, //默认显示图片
      timer: null, //定时器
      aboutname: "",
      columid: "",
      paths: "",
      zti: {},
      bannerjump: []
    };
  },
  mounted() {
    this.aboutname = this.$route.query.aboutname;
    this.columid = this.$route.query.columid;
    this.paths = this.$route.query.paths;
    this.fetchData();
  },
  filters: {
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 30) {
        return value.slice(0, 30) + "...";
      }
      return value;
    }
  },
  computed: {
    prevIndex() {
      if (this.currentIndex == 0) {
        return this.Img.length - 1;
      } else {
        return this.currentIndex - 1;
      }
    },
    //下一张
    nextIndex() {
      if (this.currentIndex == this.Img.length - 1) {
        return 0;
      } else {
        return this.currentIndex + 1;
      }
    }
  },

  methods: {
    fetchData() {
      this.fetchList();
      this.fetchColumn();
      this.fetchHotNews();
      this.runInv();
      this.fetchChildColumn();
    },
    //   获取二级栏目
    async fetchColumn() {
      await getQuotes({
        parentId: this.columid
      }).then(res => {
        if (res.data) {
          this.zti = res.data[res.data.length - 1];
        } else {
          this.zti = {};
        }
      });
    },
    // 获取列表
    async fetchList() {
      await getQuotesList({
        // id: this.columnId
        columnId: this.columnId
      }).then(res => {
        this.bannerjump = res.data.records;
        for (let i = 0; i < 3; i++) {
          this.Img.push(res.data.records[i].icon);
          this.label.push(res.data.records[i].title);
        }
      });
    },
    // 获取今日热闻
    async fetchHotNews() {
      await getNews({
        type: 2
      }).then(res => {
        this.relnew = res.data.records;
      });
    },

    // 获取子栏目
    async fetchChildColumn() {
      await getQuotes({
        parentId: this.columid
      }).then(res => {
        getQuotesList({
          columnId: res.data[res.data.length - 1].id
        }).then(res => {
          this.relhot = res.data.records;
        });
      });
    },
    //   点击 banner 跳转正文
    async handleBanner(idx) {
      await getdetail({
        id: this.bannerjump[idx].columnId
      }).then(res => {
        let bannerDetail = this.$router.resolve({
          path: "/domesticDetails",
          query: {
            titleId: this.bannerjump[idx].id
          }
        });
        window.open(bannerDetail.href, "_blank");
      });
    },
    // 跳转热闻详情、资讯详情
    async handleNewsDetail(detail) {
      await getdetail({
        id: detail.columnId
      }).then(res => {
        let routerJump = this.$router.resolve({
          path: "/domesticDetails",
          query: {
            // columid: this.columid,
            // columnNameId:res.data.id,
            titleId: detail.id
          }
        });
        window.open(routerJump.href, "_blank");
      });
    },
    // 更多
    handleMore() {
      this.$router.push({
        path: "/childLayout",
        query: {
          columnNameId: this.zti.id
        }
      });
    },
    gotoPage(index) {
      this.currentIndex = index;
    },

    runInv() {
      this.timer = setInterval(() => {
        this.gotoPage(this.nextIndex);
      }, 3000);
    },
    mouseOver() {
      clearInterval(this.timer);
    },
    mouseLeave() {
      this.runInv();
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
}
.banner {
  width: 100%;
  cursor: pointer;
  position: relative;
  img {
    width: 100%;
    height: 300px;
    display: block;
  }
  .banner_detail {
    width: 100%;
    height: 40px;
    line-height: 40px;
    position: absolute;
    right: 0;
    bottom: 0;
    // align-items: center;
    background: #51d5c3;
    .detail_title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 10px;
      color: #fff;
    }
    .detail_list {
      padding-top: 10px;
      .detail_item {
      }
      //   .current {
      //     background-color: #fff;
      //     border: none;
      //     width: 12px;
      //     height: 12px;
      //   }
    }
  }
}
.news {
  //   height: 100%;
  margin-top: 10px;
  padding: 20px 10px;
  border: 1px solid #e5e5e5;
  .news_header {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    padding-left: 15px;
    color: #010101;
    font-weight: bold;
    .hot_font {
      margin-right: 10px;
      color: #ed0000;
      font-weight: bold;
      margin-left: 10px;
      position: relative;
      &::after {
        content: "";
        width: 16px;
        height: 20px;
        position: absolute;
        top: 0px;
        right: -26px;
        display: inline-block;
        background: url(../assets/img/hot.png) no-repeat;
        background-size: 100%;
      }
    }
  }
  .news_item {
    margin: 15px 0;
    padding-left: 10px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:hover {
      color: #51d5c3;
    }
  }
}
.special_subject {
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px 10px 10px 0;
  background-color: #f2f2f2;
  .special_header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d8d8d8;
    .title {
      //   width: 90px;
      line-height: 32px;
      position: relative;
      text-align: center;
      color: #fff;
      font-size: 18px;
      background-color: #51d5c3;
      border-bottom: 2px solid #08a085;
      &::after {
        content: "";
        width: 1px;
        height: 5px;
        display: inline-block;
        border: 5px solid transparent;
        border-top: 5px solid #08a085;
        font-size: 100% 100%;
        position: absolute;
        left: 40px;
        bottom: -15px;
      }
    }
    .more {
      font-size: 16px;
      cursor: pointer;
      &:hover {
        color: #51d5c3;
      }
    }
  }
  .special_list {
    padding: 15px 10px;
    .special_list_item {
      min-height: 60px;
      padding: 10px 10px 10px 0;
      border-bottom: 1px dashed #d8d8d8;
      cursor: pointer;
      .position {
        position: relative;
        width: 100%;
        p {
          text-align: right;
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
    }
  }
}
</style>
